const INFO = {
	main: {
		title: "Akash's Portfolio",
		name: "Akash Vishwakarma",
		email: "vishwaka@usc.edu",
		logo: "../logo.png",
	},

	socials: {
		github: "https://github.com/joyboy5477",
		linkedin: "https://www.linkedin.com/in/akash5477/",
		email: "vishwaka@usc.edu",
	},

	homepage: {
		title: "Akash Vishwakarma - Innovating with AI and Software Development",
		description:
			"I'm Akash Vishwakarma, a dedicated data scientist and AI expert. As a Master's student in Computer Science at USC, I specialize in machine learning, data engineering, and software development. My portfolio highlights my work on advanced imaging algorithms, predictive analytics, and dynamic web applications. Discover my professional journey and see how I apply data and AI to innovate and tackle complex challenges.",
	},

	about: {
		title: "About Me!",
		description:
			"Hi, I'm Akash Vishwakarma, a Master's student in Computer Science at the University of Southern California with a background in AI and Software Development. I specialize in Python, data analytics, and machine learning, and I have experience in software development, data engineering, and advanced imaging algorithms. My projects include AI-driven applications and chatbot development, and I am passionate about leveraging technology for innovative solutions.",
	},

	articles: {
		title: "Professional Experience and Expertise",
		description:
			"My diverse experience spans data science, AI, and software engineering, showcasing my ability to innovate and drive efficiency through advanced data solutions and cutting-edge technologies.",
	},

	projects: [
		{
			title: "AI News",
			description:
				"Stay updated effortlessly! Discover the power of AI with our app that condenses over 500 news articles daily into crisp, 300-character summaries",
			logo: "https://cdn.jsdelivr.net/npm/programming-languages-logos/src/python/python.png",
			linkText: "View Project",
			link: "https://llms.software/general",
		},

		{
			title: "Chat with PDF",
			description:
				"Transform how you interact with PDFs! Our advanced RAG chatbot allows you to dive into documents like never before, providing precise answers and reducing response times ",
			logo: "https://cdn.jsdelivr.net/npm/programming-languages-logos/src/javascript/javascript.png",
			linkText: "View Project",
			link: "https://ai.hbofficial.com/",
		},

		{
			title: "Time Series Forecasting Project: Apple Inc. Trading Volume",
			description:
				"Unlock the secrets of the stock market with our cutting-edge analysis of Apple Inc.’s trading volumes",
			logo: "https://cdn.jsdelivr.net/npm/programming-languages-logos/src/python/python.png",
			linkText: "View Project",
			link: "https://github.com/joyboy5477/TimeSeriesForecasting/blob/main/appleStockAnalysis.ipynb",
		},
	],
};

export default INFO;
