const SEO = [
	{
		page: "home",
		description:
			"I am a data scientist and AI specialist with a strong background in Computer Science. I have experience in developing advanced algorithms, predictive models, and robust web applications using various technologies.",
		keywords: [
			"Akash",
			"Akash Vishwakarma",
			"data scientist",
			"AI specialist",
		],
	},

	{
		page: "about",
		description:
			"I am a data enthusiast and AI specialist pursuing a Master's in Computer Science at USC. My expertise spans machine learning, data engineering, and software development, and I have worked on numerous innovative projects.",
		keywords: [
			"Akash",
			"Portfolio",
			"About",
			"Akash Vishwakarma",
			"data scientist",
			"AI specialist",
		],
	},

	{
		page: "articles",
		description:
			"Explore my thoughts on data science, AI, machine learning, and software development. These articles reflect my insights and experiences in the field.",
		keywords: [
			"Akash",
			"Akash Vishwakarma",
			"data science articles",
			"AI insights",
		],
	},

	{
		page: "projects",
		description:
			"Discover the variety of projects I have worked on, showcasing my skills in data science, AI, and software development. Many of these projects are open-source and welcome contributions.",
		keywords: [
			"Akash",
			"Akash Vishwakarma",
			"data science projects",
			"AI projects",
		],
	},

	{
		page: "contact",
		description:
			"If you're interested in collaborating on a project or have any inquiries, feel free to reach out to me. I'm always open to new ideas and opportunities.",
		keywords: ["Akash", "Akash Vishwakarma", "contact", "collaboration"],
	},
];

export default SEO;
