import React from "react";

function article_1() {
	return {
		date: "May 2024 - Present",
		title: "Data Engineering Intern | CommonXR | Los Angeles, California",
		description:
			"Migrated data ingestion queries from Power BI to Azure Data Factory, improving performance and scalability. Implemented incremental refresh logic for data processing, reducing data load times by 50% and improving query efficiency. Configured Azure Data Factory pipelines to automate ETL processes and SQL script execution, increasing data processing by 80%.",
		keywords: [
			"Data Engineering Intern",
			"CommonXR",
			"Azure Data Factory",
			"ETL processes",
			"Akash Vishwakarma",
		],
		style: `
				.experience-content {
					display: flex;
					flex-direction: column;
					align-items: center;
				}

				.randImage {
					align-self: center;
					outline: 2px solid blue;
				}
				`,
		body: (
			<React.Fragment>
				<div className="experience-content">
					<div className="paragraph">
						Migrated data ingestion queries from Power BI to Azure
						Data Factory, improving performance and scalability.
						Implemented incremental refresh logic for data
						processing, reducing data load times by 50% and
						improving query efficiency. Configured Azure Data
						Factory pipelines to automate ETL processes and SQL
						script execution, increasing data processing by 80%.
					</div>
					<img
						src="https://picsum.photos/200/300"
						alt="random"
						className="randImage"
					/>
				</div>
			</React.Fragment>
		),
	};
}

function article_2() {
	return {
		date: "Jan 2024 - May 2024",
		title: "Machine Learning Research Assistant | University of Southern California | Los Angeles, California",
		description:
			"Developed advanced imaging algorithms for micro-CT image preprocessing, enhancing void detection in COSB materials. Leveraged cutting-edge models including 3D U-Net, 2D U-Net, and KNN to significantly improve the accuracy by 95%. Enhanced boundary depiction and void analysis with FCN and U-Net models; implemented semi-supervised learning for efficient 3D micro-CT dataset labeling.",
		style: ``,
		keywords: [
			"Machine Learning Research Assistant",
			"University of Southern California",
			"micro-CT imaging",
			"3D U-Net",
			"Akash Vishwakarma",
		],
		body: (
			<React.Fragment>
				<div className="experience-content">
					<div className="paragraph">
						Developed advanced imaging algorithms for micro-CT image
						preprocessing, enhancing void detection in COSB
						materials. Leveraged cutting-edge models including 3D
						U-Net, 2D U-Net, and KNN to significantly improve the
						accuracy by 95%. Enhanced boundary depiction and void
						analysis with FCN and U-Net models; implemented
						semi-supervised learning for efficient 3D micro-CT
						dataset labeling.
					</div>
				</div>
			</React.Fragment>
		),
	};
}

function article_3() {
	return {
		date: "Nov 2023 - April 2024",
		title: "Software Engineer | Vermont Slauson Economic Development Corporation | Los Angeles, California",
		description:
			"Created an end-to-end website using React, Node.js, and MongoDB for a CRM system, consolidating 30+ hours of manual data management. Collaborated with more than 5 team members to develop admin application, which substantially increased the data accessibility. Implemented data visualization dashboards, enabling real-time business insights and analytics.",
		style: ``,
		keywords: [
			"Software Engineer",
			"Vermont Slauson Economic Development Corporation",
			"React",
			"Node.js",
			"MongoDB",
			"Akash Vishwakarma",
		],
		body: (
			<React.Fragment>
				<div className="experience-content">
					<div className="paragraph">
						Created an end-to-end website using React, Node.js, and
						MongoDB for a CRM system, consolidating 30+ hours of
						manual data management. Collaborated with more than 5
						team members to develop admin application, which
						substantially increased the data accessibility.
						Implemented data visualization dashboards, enabling
						real-time business insights and analytics.
					</div>
				</div>
			</React.Fragment>
		),
	};
}

function article_4() {
	return {
		date: "Mar 2020 - July 2020",
		title: "Data Analyst Intern | Bracket Infinity | Mumbai, India",
		description:
			"Developed a Python-based web scraping algorithm to retrieve over 10,000 missing images and comments from an e-commerce site, fixing errors and data gaps. Analyzed review data to reveal customer trends, influencing marketing strategies. Transformed raw data into actionable insights using Tableau, simplifying analysis for non-technical stakeholders.",
		style: ``,
		keywords: [
			"Data Analyst Intern",
			"Bracket Infinity",
			"web scraping",
			"Python",
			"Tableau",
			"Akash Vishwakarma",
		],
		body: (
			<React.Fragment>
				<div className="experience-content">
					<div className="paragraph">
						Developed a Python-based web scraping algorithm to
						retrieve over 10,000 missing images and comments from an
						e-commerce site, fixing errors and data gaps. Analyzed
						review data to reveal customer trends, influencing
						marketing strategies. Transformed raw data into
						actionable insights using Tableau, simplifying analysis
						for non-technical stakeholders.
					</div>
				</div>
			</React.Fragment>
		),
	};
}

const myExperience = [article_1, article_2, article_3, article_4];

export default myExperience;
